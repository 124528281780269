const routes = {
  home: "/store/home",
  search: "/store/home/search",
  address: "/store/home/address",
  transactionDetail: "/store/home/transaction-detail",
  invoice: "/store/home/invoice",
  tracking: "/store/home/tracking",
  problemsDetail: "/store/home/problems-detail",
  review: "/store/home/review",
  reviewDetail: "/store/home/review-detail",
  addAddress: "/store/home/address/add",
  editAddress: "/store/home/address/edit",
  pinAddress: "/store/home/address/add/pin",
  mamaPoint: "/store/home/mama-point",
  membership: "/store/home/membership",
  listMembership: "/store/home/membership/list",
  login: "/store/login",
  register: "/store/register",
  registerCompletion: "/store/register-completion",
  productDetails: "/store/product-details",
  reviewDetails: "/store/review-details",
  cart: "/store/cart",
  voucher: "/store/voucher",
  checkout: "/store/checkout",
  payment: "/store/payment",
  paymentMethod: "/store/payment-method",
  paymentGuide: "/store/payment-guide",
  notifications: "/store/notifications",
  verification: "/store/verification",
  otp: "/store/otp",
  termsCondition: "/store/terms-condition",
  privacyPolicy: "/store/privacy-policy",
  about: "/store/about",

  homepage: "/",
  contactUs: "/contact-us",
  careerDetail: "/career-detail/:id",
  careerHome: "/career-home",
  articleDetail: "/article-detail/:id",
  reseller: "/reseller",
  afiliator: "/afiliator",
  faq: "/faq",
  article: "/article",
  articleCategory: "/article-category",
  ourStory: "/our-story",
  cekResi: "/cekresi",
  aboutUs: "/about-us",
  cekDetail: "/cekdetail/:id?",
};

export default routes;
