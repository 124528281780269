import { useState, useRef } from "react";
import CardJobList from "../../components/card-job-list";
import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import { useCareers } from "./queries/use-careers";
import { useDivisions } from "./queries/use-divisions";

export default function CareerHome() {
  const divisions = useDivisions();

  const difIdRef = useRef(0);
  const keywordRef = useRef("");
  const typeRef = useRef("");

  const [divisionId, setDivisionId] = useState(0);
  const [type, setType] = useState("");

  const careers = useCareers(1, keywordRef.current, divisionId, type);

  const handleSearch = () => {
    setDivisionId(difIdRef.current);
    setType(typeRef.current);
    careers.refetch();
  };

  return (
    <div className="wrapper-pages">
      <Navbar />

      <div className="career-home">
        {/* Dekstop Version */}
        <section className="hero">
          <div className="container">
            <div className="left">
              <h1>
                <span>
                  Temukan <br />
                  Pekerjaan{" "}
                </span>{" "}
                Impian Anda
              </h1>
              <p>
                Bergabunglah dan menjadi bagian dari tim kami. Mari buat inovasi
                global dan hasilkan dampak sosial.
              </p>

              <div className="filter-container">
                <div className="item">
                  <svg
                    className="prefix-icon"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 19.0312C5.31517 19.0312 1.5 15.2161 1.5 10.5312C1.5 5.84642 5.31517 2.03125 10 2.03125C14.6848 2.03125 18.5 5.84642 18.5 10.5312C18.5 15.2161 14.6848 19.0312 10 19.0312ZM10 3.03125C7.76854 3.03125 5.88389 3.76162 4.55592 5.09078C3.22803 6.41987 2.5 8.3044 2.5 10.5312C2.5 12.7581 3.22803 14.6426 4.55592 15.9717C5.88389 17.3009 7.76854 18.0312 10 18.0312C12.2315 18.0312 14.1161 17.3009 15.4441 15.9717C16.772 14.6426 17.5 12.7581 17.5 10.5312C17.5 8.3044 16.772 6.41987 15.4441 5.09078C14.1161 3.76162 12.2315 3.03125 10 3.03125Z"
                      fill="#DE8D8D"
                      stroke="#DE8D8D"
                    />
                    <path
                      d="M21.7167 23.0313C21.5159 23.0313 21.3191 22.9588 21.1603 22.8002L17.7268 19.3691C17.4244 19.0669 17.4244 18.5603 17.7268 18.2581C18.0294 17.9556 18.5371 17.9556 18.8397 18.2581L22.2732 21.6891C22.5756 21.9913 22.5756 22.498 22.2732 22.8002C22.1144 22.9588 21.9176 23.0313 21.7167 23.0313Z"
                      fill="#DE8D8D"
                      stroke="#DE8D8D"
                    />
                  </svg>

                  <input
                    type="text"
                    placeholder="Nama pekerjaan..."
                    onChange={(e) => {
                      keywordRef.current = e.target.value;
                    }}
                  />
                </div>
                <svg
                  width="1"
                  height="33"
                  viewBox="0 0 1 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    y1="-0.5"
                    x2="32"
                    y2="-0.5"
                    transform="matrix(4.37114e-08 1 1 -4.37114e-08 1 0.53125)"
                    stroke="#E4E5E8"
                  />
                </svg>
                <div className="item">
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.15288 14.7582L3 11.5312C3 11.5312 8.478 15.6975 12.5 15.5312C16.1655 15.3797 21 11.5312 21 11.5312L20.8471 14.7582C20.6829 18.1998 20.6007 19.9206 19.4412 20.9759C18.2816 22.0312 16.4728 22.0312 12.8554 22.0312H11.1446C7.52716 22.0312 5.71843 22.0312 4.55885 20.9759C3.39927 19.9206 3.31714 18.1998 3.15288 14.7582Z"
                      stroke="#DE8D8D"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2.84718 10.9743C4.54648 14.2057 8.3792 15.5312 12 15.5312C15.6208 15.5312 19.4535 14.2057 21.1528 10.9743C21.964 9.43181 21.3498 6.53125 19.352 6.53125H4.648C2.65023 6.53125 2.03603 9.43181 2.84718 10.9743Z"
                      stroke="#DE8D8D"
                      strokeWidth="1.5"
                    />
                    <path
                      d="M12 11.5312H12.009"
                      stroke="#DE8D8D"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16 6.53125L15.9117 6.22219C15.4717 4.68214 15.2517 3.91212 14.7279 3.47168C14.2041 3.03125 13.5084 3.03125 12.117 3.03125H11.883C10.4916 3.03125 9.79587 3.03125 9.2721 3.47168C8.74832 3.91212 8.52832 4.68214 8.0883 6.22219L8 6.53125"
                      stroke="#DE8D8D"
                      strokeWidth="1.5"
                    />
                  </svg>

                  <select
                    onChange={(e) => {
                      typeRef.current = e.target.value;
                    }}
                  >
                    <option key={""} value="">
                      Kategori
                    </option>

                    <option value="intern">Magang</option>
                    <option value="full_time">Penuh Waktu</option>
                  </select>
                </div>
                <div className="item">
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.15288 14.7582L3 11.5312C3 11.5312 8.478 15.6975 12.5 15.5312C16.1655 15.3797 21 11.5312 21 11.5312L20.8471 14.7582C20.6829 18.1998 20.6007 19.9206 19.4412 20.9759C18.2816 22.0312 16.4728 22.0312 12.8554 22.0312H11.1446C7.52716 22.0312 5.71843 22.0312 4.55885 20.9759C3.39927 19.9206 3.31714 18.1998 3.15288 14.7582Z"
                      stroke="#DE8D8D"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2.84718 10.9743C4.54648 14.2057 8.3792 15.5312 12 15.5312C15.6208 15.5312 19.4535 14.2057 21.1528 10.9743C21.964 9.43181 21.3498 6.53125 19.352 6.53125H4.648C2.65023 6.53125 2.03603 9.43181 2.84718 10.9743Z"
                      stroke="#DE8D8D"
                      strokeWidth="1.5"
                    />
                    <path
                      d="M12 11.5312H12.009"
                      stroke="#DE8D8D"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16 6.53125L15.9117 6.22219C15.4717 4.68214 15.2517 3.91212 14.7279 3.47168C14.2041 3.03125 13.5084 3.03125 12.117 3.03125H11.883C10.4916 3.03125 9.79587 3.03125 9.2721 3.47168C8.74832 3.91212 8.52832 4.68214 8.0883 6.22219L8 6.53125"
                      stroke="#DE8D8D"
                      strokeWidth="1.5"
                    />
                  </svg>

                  <select
                    onChange={(e) => {
                      difIdRef.current = e.target.value;
                    }}
                  >
                    <option key={0} value="">
                      Divisi
                    </option>

                    {divisions.isSuccess &&
                      divisions.data.map((division) => (
                        <option key={division.id} value={division.id}>
                          {division.name}
                        </option>
                      ))}
                  </select>
                </div>

                <button
                  type="button"
                  className="button-custom text-nowrap"
                  onClick={handleSearch}
                >
                  Cari Pekerjaan
                </button>
              </div>
              <div className="note">
                <span>Contoh :</span> Designer, Digital Marketing, Sales, Copy
                Writing, dll.
              </div>
            </div>

            <div className="right">
              <img src="images/career-hero.png" alt="" />
            </div>
          </div>
        </section>

        {/* Mobile Version */}
        <section className="search-job">
          <div className="container">
            <div className="input-group">
              <div className="item">
                <svg
                  className="prefix-icon"
                  width="23"
                  height="23"
                  viewBox="0 0 23 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.3667 17.3174C5.09528 17.3174 1.6167 13.8388 1.6167 9.56738C1.6167 5.29596 5.09528 1.81738 9.3667 1.81738C13.6381 1.81738 17.1167 5.29596 17.1167 9.56738C17.1167 13.8388 13.6381 17.3174 9.3667 17.3174ZM9.3667 2.65072C7.31194 2.65072 5.57313 3.32343 4.34682 4.55084C3.12058 5.77818 2.45003 7.51687 2.45003 9.56738C2.45003 11.6179 3.12058 13.3566 4.34682 14.5839C5.57313 15.8113 7.31194 16.484 9.3667 16.484C11.4215 16.484 13.1603 15.8113 14.3866 14.5839C15.6128 13.3566 16.2834 11.6179 16.2834 9.56738C16.2834 7.51687 15.6128 5.77818 14.3866 4.55084C13.1603 3.32343 11.4215 2.65072 9.3667 2.65072Z"
                    fill="#DE8D8D"
                    stroke="#DE8D8D"
                  />
                  <path
                    d="M20.1074 20.9834C19.9337 20.9834 19.7641 20.921 19.6267 20.7838L16.4794 17.6387C16.2185 17.3779 16.2185 16.9399 16.4794 16.6791C16.7405 16.4182 17.1795 16.4182 17.4406 16.6791L20.588 19.8242C20.8489 20.085 20.8489 20.523 20.588 20.7838C20.4507 20.921 20.281 20.9834 20.1074 20.9834Z"
                    fill="#DE8D8D"
                    stroke="#DE8D8D"
                  />
                </svg>

                <input
                  className="text"
                  type="text"
                  placeholder="Nama pekerjaanx..."
                  onChange={(e) => {
                    keywordRef.current = e.target.value;
                  }}
                />
              </div>

              <div className="item">
                <svg
                  className="prefix-icon"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.15288 14.7582L3 11.5312C3 11.5312 8.478 15.6975 12.5 15.5312C16.1655 15.3797 21 11.5312 21 11.5312L20.8471 14.7582C20.6829 18.1998 20.6007 19.9206 19.4412 20.9759C18.2816 22.0312 16.4728 22.0312 12.8554 22.0312H11.1446C7.52716 22.0312 5.71843 22.0312 4.55885 20.9759C3.39927 19.9206 3.31714 18.1998 3.15288 14.7582Z"
                    stroke="#DE8D8D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.84718 10.9743C4.54648 14.2057 8.3792 15.5312 12 15.5312C15.6208 15.5312 19.4535 14.2057 21.1528 10.9743C21.964 9.43181 21.3498 6.53125 19.352 6.53125H4.648C2.65023 6.53125 2.03603 9.43181 2.84718 10.9743Z"
                    stroke="#DE8D8D"
                    strokeWidth="1.5"
                  />
                  <path
                    d="M12 11.5312H12.009"
                    stroke="#DE8D8D"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16 6.53125L15.9117 6.22219C15.4717 4.68214 15.2517 3.91212 14.7279 3.47168C14.2041 3.03125 13.5084 3.03125 12.117 3.03125H11.883C10.4916 3.03125 9.79587 3.03125 9.2721 3.47168C8.74832 3.91212 8.52832 4.68214 8.0883 6.22219L8 6.53125"
                    stroke="#DE8D8D"
                    strokeWidth="1.5"
                  />
                </svg>

                <select name="" id="">
                  <option value="">Kategori</option>
                  <option value="">Satu</option>
                  <option value="">Dua</option>
                </select>
              </div>

              <button className="button-custom">Cari Pekerjaan</button>
              <div className="note">
                <p>
                  <span>Contoh</span>
                </p>
                <p>: Designer, Digital Marketing, Sales, Copy Writing, dll.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="job-list">
          <div className="container">
            {/* Careers */}
            {careers.isLoading && (
              <div className="py-2 text-teal-900 font-bold">Loading...</div>
            )}

            {careers.error instanceof Error && (
              <div>{careers.error.message}</div>
            )}

            <div className="wrapper">
              {careers.isSuccess &&
                careers.data.map((career, index) => (
                  <CardJobList
                    key={index}
                    category="Designer"
                    images={
                      career.career_posters[0].image_url_text ?? "/svg/pen.svg"
                    }
                    {...career}
                  />
                ))}

              {/* empty state */}
              {careers.isSuccess && careers.data.length === 0 && "No Job Found"}
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
}
