import { useEffect, useState } from "react";
import AccordionCustom from "../components/accordion";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import TitleHeader from "../components/title-header";
import { useFaqs } from "./homepage/queries/use-faq";

export default function Faq() {
  // const faqs = useFaqs();
  const [faqs, setFaqs] = useState({
    isLoading: false,
    isFetching: false,
    isSuccess: true,
    data: [
      {
        name: "Produk",
        faqs: [
          { 
            subtitle: "Apa itu Mamabear ?", 
            text: "Mamabear adalah penyedia produk- produk pelancar ASI dengan bahan- bahan alami. Founder Agnes Susanti Widjaja adalah mama menyusui sekaligus Bachelor degree in Science in Food Technology & Nutrition lulusan Royal Melbourne Institute of Technology. Melalui riset, pengalaman dan dedikasinya, Mamabear hadir sebagai solusi ASI terbaik bagi Anda. Baca selengkapnya." 
          },
          { 
            subtitle: "Apa saja produk- produk Mamabear?", 
            text: "Mamabear menyediakan produk- produk pelancar ASI yaitu : Teh Pelancar ASI, Minuman Bubuk (Zoya Mix dan Almond Mix), dan Almond Oat Cookies. Kami juga menawarkan Kantong ASI dan produk menarik lainnya. Zoya Mix dan Almond Oat Cookies juga bisa dikonsumsi oleh semua usia, termasuk anak- anak sebagai cemilan atau sarapan sehat." 
          },
          {
            subtitle:'Apa keunggulan produk Mamabear?',
            text: 'Kami hanya menggunakan bahan – bahan alami berkualitas yang diproduksi dengan teknologi dan proses terbaik. Seluruh produk kami aman dan efektif, serta praktis untuk ibu menyusui.Seluruh produk Mamabear juga telah lulus uji BPOM dan tersertifikasi Halal.Zoya Mix dan Almond Oat Cookies juga bisa dikonsumsi oleh semua usia, termasuk anak- anak sebagai cemilan atau sarapan sehat.',
          },
          {
            subtitle:'Apa bahan- bahan produk Mamabear?',
            text: 'Untuk info bahan- bahan kami, silahkan klik disini.',
          },
          {
            subtitle:'Produk pelancar ASI yang paling cocok untuk saya?',
            text: 'Setiap produk Mamabear dibuat untuk ibu menyusui. Namun, setiap hasil yang dirasakan akan berbeda- beda pada setiap individual. Sesuai pengalaman kami, Teh Pelancar ASI dan Zoya Milk efektif untuk meningkatkan produksi ASI. Sedangkan Almond Mix dan Almond Oat Cookies bisa memperkaya kualitas ASI.Beberapa ibu menyusui juga sangat cocok dengan produk teh, sedangkan yang lainnya lebih merasakan hasil dari minuman bubuk. Untuk rekomendasi terbaik, kami sangat menyarankan untuk mencoba seluruh produk Mamabear. Jika dikombinasikan secara bersamaan, hasilnya juga akan lebih maksimal.',
          },
          {
            subtitle:'Apa perbedaan antara Zoya Mix dan Almond Mix?',
            text: 'Zoya Mix dan Almond Mix adalah produk minuman bubuk dari bahan – bahan tumbuhan yang didesain untuk meningkatkan produksi ASI. Keduanya juga punya rasa lezat dan praktis digunakan. Perbedaan antara kedua produk ini lebih ke bahan – bahan yang digunakan dan rasa yang tersedia. Silahkan pilih sesuai selera Anda!',
          },
        ]
      },
      {
        name: "Order",
        faqs: [
          { 
            subtitle: "Bagaimana cara pesan produk Mamabear?", 
            text: "Buat akun dengan cara mendaftarkan email Anda. Log in dan tambahkan produk ke keranjang, lalu lengkapi pembayaran untuk menyelesaikan pesanan. Produk Mamabear juga tersedia di mitra resmi kami di Tokopedia, Shopee, JD.ID. Blibli dan Bukalapak." 
          },
          {
            subtitle:'Apa saja sistem pembayaran yang tersedia?',
            text:'Kami menerima berbagai bentuk pembayaran, antara lain transfer bank (BCA & Mandiri), kartu kredit, GoPay dan alfamart.',
          },
          {
            subtitle:'Berapa lama untuk proses pengiriman?',
            text:'Pesanan Anda akan diproses dalam 3-4 hari kerja. Lama pengiriman tergantung pada lokasi tujuan.',
          },
          {
            subtitle:'Apakah saya bisa mengganti pesanan atau alamat jika pembayaran sudah terkonfirmasi?',
            text:'Untuk mengubah pesanan, langsung WhatsApp untuk menghubungi tim kami. Untuk mengubah alamat, silahkan batalkan pesanan untuk membuat pesanan baru.',
          },
          {
            subtitle:'Apakah ada diskon belanja?',
            text:'Silahkan subscribe ke newsletter kami untuk mendapatkan info penawaran dan update menarik lainnya!',
          },
        ]
      }
    ],
    error: null,
  });
  // console.log("ini data" , faqs.data);

  return (
    <div className="wrapper-pages">
      <Navbar />
      <TitleHeader
        title={"Frequently Asked Questions"}
        bread={
          <div
            dangerouslySetInnerHTML={{ __html: "Bantuan / <span>FAQ</span>" }}
          />
        }
      />

      {faqs.isLoading && (
        <div className="py-2 text-teal-900 font-bold">Loading...</div>
      )}

      {faqs.isFetching && (
        <div className="py-2 text-teal-900 font-bold">Fetching...</div>
      )}

      {faqs.error instanceof Error && <div>{faqs.error.message}</div>}

      <div className="container">
        <div className="faq-pages">
          {faqs.isSuccess &&
            faqs.data.map((faq, index) => (
              <div className="item" style={{ marginTop: "40px" }}>
                <h1>{faq.name}</h1>
                <div className="accordion" id="accordionExample">
                  {/* {faq.faqs.map((item) => (
                    <>
                      <AccordionCustom
                        desc={item.text}
                        title={item.subtitle}
                        target={"target2"}
                        show={true}
                        collapsed={true}
                      />
                    </>
                  ))} */}
                  {faq.faqs.map((item, idx) => (
                    <AccordionCustom
                      key={idx}
                      desc={item.text}
                      title={item.subtitle}
                      target={`target-${index}-${idx}`}
                      show={false}
                      collapsed={true}
                    />
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}
