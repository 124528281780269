import SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Grid } from "swiper/modules";
import ArticleCards from "../../../components/article-card";
import { useArticles } from "../queries/use-articles";

SwiperCore.use([Pagination]);

export default function ArticleList({ category }) {
  const articles = useArticles(category.id);

  return (
    <div>
      {articles.isLoading && (
        <div className="py-2 text-teal-900 font-bold">Loading...</div>
      )}

      {articles.error instanceof Error && <div>{articles.error.message}</div>}

      <Swiper
      className="articleSwiper"
        breakpoints={{
          50: {
            slidesPerView: 1,
            grid: {
              rows: 1,
              fill: "row",
            },
          },
          640: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 3,
          },
          1200: {
            spaceBetween: 16,
            slidesPerView: 3,
          },
        }}
        spaceBetween={25}
        slidesPerView={1}
        grid={{ rows: 1 }}
        loop={true}
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        modules={[Navigation, Pagination, Scrollbar, A11y, Grid]}
      >
        {articles.isSuccess && (
        articles.data.length > 0 ? (
          articles.data.map((article, index) => (
            <SwiperSlide key={index}>
              <ArticleCards {...article} />
            </SwiperSlide>
          ))
        ) : (
          <div>Tidak ada artikel yang tersedia.</div>
        )
      )}

      </Swiper>
    </div>
  );
}
