import SwiperCore from "swiper";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Grid } from "swiper/modules";
import ArticleCards from "../../../components/article-card";
import { useArticles } from "../queries/use-articles";
import { useState } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import { NavLink } from "react-router-dom";
import routes from "../../../../../routes";

SwiperCore.use([Pagination]);

export default function ArticleList({ category, keyword }) {
  const [page, setPage] = useState(1);
  const debouncedKeyword = useDebounce(keyword, 600);
  const articles = useArticles(category.id, page, debouncedKeyword);

  const handleSlideChange = (swiper) => {
    if (swiper.isEnd) {
      // console.log('swiper.isEnd');
      // console.log(articles.data.length);
      // if (page < totalPages) {
      setPage(page + 1);
      // }
    }
  };

  const SwiperButtonNext = () => {
    const swiper = useSwiper();
    return (
      <svg
        onClick={() => {
          swiper.slideNext();
          // console.log(`swiper.previousIndex ${swiper.previousIndex}`)
          // console.log(`swiper.activeIndex ${swiper.activeIndex}`)
        }}
        width="35"
        height="35"
        viewBox="0 0 35 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.5129 12.8309L14.5387 12.8051L14.5129 12.8309C14.4079 12.7259 14.3246 12.6012 14.2678 12.464C14.2109 12.3268 14.1817 12.1797 14.1817 12.0312C14.1817 11.8827 14.2109 11.7357 14.2678 11.5985C14.3246 11.4613 14.4079 11.3366 14.5129 11.2316C14.6179 11.1266 14.7426 11.0433 14.8798 10.9865C15.017 10.9297 15.164 10.9004 15.3125 10.9004C15.461 10.9004 15.6081 10.9297 15.7453 10.9865C15.8825 11.0433 16.0071 11.1266 16.1121 11.2316L21.5809 16.7004C21.5809 16.7004 21.5809 16.7004 21.5809 16.7004C21.6859 16.8054 21.7693 16.93 21.8262 17.0672C21.8831 17.2044 21.9123 17.3515 21.9123 17.5C21.9123 17.6485 21.8831 17.7956 21.8262 17.9328C21.7693 18.07 21.6859 18.1947 21.5809 18.2996L16.1121 23.7684C15.9 23.9804 15.6124 24.0996 15.3125 24.0996C15.0126 24.0996 14.725 23.9804 14.5129 23.7684C14.3008 23.5563 14.1817 23.2687 14.1817 22.9687C14.1817 22.6688 14.3008 22.3812 14.5129 22.1691L14.5387 22.1949L14.5129 22.1691L19.1834 17.5L14.5129 12.8309ZM9.62075 29.2921C11.953 30.8505 14.695 31.6823 17.5 31.6823C21.2601 31.6783 24.8652 30.1828 27.524 27.524C30.1828 24.8652 31.6783 21.2601 31.6823 17.5C31.6823 14.695 30.8505 11.953 29.2922 9.62074C27.7338 7.28847 25.5188 5.47069 22.9273 4.39727C20.3359 3.32384 17.4843 3.04299 14.7332 3.59021C11.9821 4.13744 9.45504 5.48817 7.47161 7.4716C5.48818 9.45503 4.13745 11.9821 3.59022 14.7332C3.043 17.4843 3.32385 20.3359 4.39728 22.9273C5.4707 25.5188 7.28848 27.7338 9.62075 29.2921Z"
          fill="#DE8D8D"
          stroke="#DE8D8D"
          strokeWidth="0.0729167"
        />
      </svg>
    );
  };
  const SwiperButtonPrev = () => {
    const swiper = useSwiper();

    return (
      <svg
        onClick={() => {
          swiper.slidePrev();
        }}
        width="35"
        height="36"
        viewBox="0 0 35 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.4871 12.8934L20.4613 12.8676L20.4871 12.8934C20.5921 12.7884 20.6754 12.6637 20.7322 12.5265C20.7891 12.3893 20.8183 12.2422 20.8183 12.0937C20.8183 11.9452 20.7891 11.7982 20.7322 11.661C20.6754 11.5238 20.5921 11.3991 20.4871 11.2941C20.3821 11.1891 20.2574 11.1058 20.1202 11.049C19.983 10.9922 19.836 10.9629 19.6875 10.9629C19.539 10.9629 19.3919 10.9922 19.2547 11.049C19.1175 11.1058 18.9929 11.1891 18.8879 11.2941L13.4191 16.7629C13.4191 16.7629 13.4191 16.7629 13.4191 16.7629C13.3141 16.8679 13.2307 16.9925 13.1738 17.1297C13.1169 17.2669 13.0877 17.414 13.0877 17.5625C13.0877 17.711 13.1169 17.8581 13.1738 17.9953C13.2307 18.1325 13.3141 18.2572 13.4191 18.3621L18.8879 23.8309C19.1 24.0429 19.3876 24.1621 19.6875 24.1621C19.9874 24.1621 20.275 24.0429 20.4871 23.8309C20.6992 23.6188 20.8183 23.3312 20.8183 23.0312C20.8183 22.7313 20.6992 22.4437 20.4871 22.2316L20.4613 22.2574L20.4871 22.2316L15.8166 17.5625L20.4871 12.8934ZM25.3793 29.3546C23.047 30.913 20.305 31.7448 17.5 31.7448C13.7399 31.7408 10.1348 30.2453 7.47599 27.5865C4.81715 24.9277 3.32167 21.3226 3.3177 17.5625C3.31771 14.7575 4.14948 12.0155 5.70785 9.68324C7.26622 7.35097 9.48119 5.53319 12.0727 4.45977C14.6641 3.38634 17.5157 3.10549 20.2668 3.65271C23.0179 4.19994 25.545 5.55067 27.5284 7.5341C29.5118 9.51753 30.8625 12.0446 31.4098 14.7957C31.957 17.5468 31.6761 20.3984 30.6027 22.9898C29.5293 25.5813 27.7115 27.7963 25.3793 29.3546Z"
          fill="#DE8D8D"
          stroke="#DE8D8D"
          strokeWidth="0.0729167"
        />
      </svg>
    );
  };

  return (
    <div className="article-card-wrapper">
      <div className="top">
        <div>
          <p className="">Kategori</p>
          <h1>{category.name}</h1>
        </div>
        <p>
          Baca artikel terkait {category.name}, dapatkan artikel menarik lainnya
          dengan eksplor lebih lanjut
        </p>
        <button>
          Lihat Semua
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.0002 12.5L4.00024 12.5"
              stroke="white"
              strokeWidth="0.75"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15 7.5L19.6464 12.1464C19.8131 12.3131 19.8964 12.3964 19.8964 12.5C19.8964 12.6036 19.8131 12.6869 19.6464 12.8536L15 17.5"
              stroke="white"
              strokeWidth="0.75"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>

      <Swiper
        breakpoints={{
          50: {
            slidesPerView: 1,
            grid: {
              rows: 1,
              fill: "row",
            },
          },
          640: {
            slidesPerView: 3,
          },
          992: {
            slidesPerView: 3,
            grid: {
              rows: 2,
              fill: "row",
            },
          },
          1200: {
            // spaceBetween: 35,
            slidesPerView: 3,
            grid: {
              rows: 2,
              fill: "row",
            },
          },
        }}
        spaceBetween={25}
        slidesPerView={1}
        grid={{ rows: 1 }}
        loop={false}
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        onSlideChange={handleSlideChange}
        modules={[Navigation, Pagination, Scrollbar, A11y, Grid]}
      >
        <SwiperSlide className="swiper-slide-dekstop">
          <div className="top desktop">
            <div>
              <p className="">Kategori</p>
              <h1>{category.name}</h1>
            </div>
            <p>
              Baca artikel terkait {category.name}, dapatkan artikel menarik
              lainnya dengan eksplor lebih lanjut
            </p>
            <NavLink to={routes.articleCategory} state={category}>
              <button>
                Lihat Semua
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.0002 12.5L4.00024 12.5"
                    stroke="white"
                    strokeWidth="0.75"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15 7.5L19.6464 12.1464C19.8131 12.3131 19.8964 12.3964 19.8964 12.5C19.8964 12.6036 19.8131 12.6869 19.6464 12.8536L15 17.5"
                    stroke="white"
                    strokeWidth="0.75"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </NavLink>
          </div>
        </SwiperSlide>

        {articles.isSuccess &&
          articles.data.map((article, index) => (
            <SwiperSlide key={index}>
              <ArticleCards {...article} />
            </SwiperSlide>
          ))}

        <div className="navigation-carousel">
          <div className="navigation-item">
            <SwiperButtonPrev />
            <SwiperButtonNext />
          </div>
        </div>
      </Swiper>
    </div>
  );
}
